import React from 'react'
import PlanCards from './PlanCards'

const Pricing = () => {
  return (
    <div >
        <PlanCards/>
    </div>
  )
}

export default Pricing