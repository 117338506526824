import "./App.css";
import Drawer from "./component/Drawer";
import { createTheme, ThemeProvider } from "@mui/material";
import Dashboard from "./component/Dashboard/Dashboard";
import Project from "./component/Projects/Project";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Faq from "./component/Faq/Faq";
import Setting from "./component/Projects/Setting";
import CreateProject from "./component/Projects/CreateProject";
import StepperProcess from "./component/Projects/StepperProcess";
import ForgotPassword from "./component/Projects/ForgotPassword";
import Pricing from "./component/Pricing/Pricing";

function App() {
  const theme = createTheme();
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router basename="/">
          <Drawer>
            <Routes>
              <Route path={`/`} element={<Dashboard />} />
              <Route path={`/Project`} element={<Project />} />
              <Route path={`/Faq`} element={<Faq />} />
              <Route path={`/Settings`} element={<Setting />} />
              <Route path={`/Forgot`} element={<ForgotPassword />} />
              <Route path={`/Create`} element={<CreateProject />} />
              <Route path={`/Create/Process`} element={<StepperProcess />} />
              <Route path={`/Pricing`} element={<Pricing />} />
            </Routes>
          </Drawer>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
