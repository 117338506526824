import React from "react";
import { makeStyles } from "@mui/styles";
import fileIcon from "../../images/projectimg.svg";
import { Container, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import Grid from "@mui/material/Grid";
const useStyle = makeStyles((theme) => ({
  cardbody: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // margin: "12px 12px 15px 12px",
  },
  fileiconstyle: {
    width: "22px",
  },
  title: {
    fontSize: "14px !important",
    fontFamily: "Poppins !important",
    fontWeight: "600 !important",
    marginRight: "30px !important",
  },
  startIcn: {
    position: "absolute",
    width: "18px !important",
    right: "0",
    top: "0",
    marginRight: "2px",
  },
}));
const CardItems = () => {
  const classes = useStyle();
  return (
    <>
      <Container>
        <Grid container spacing={4}>
          <Grid item lg={3} md={4} sm={4}>
            <Card className={classes.cardbody}>
              <CardActionArea>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img src={fileIcon} className={classes.fileiconstyle} />
                    <Typography className={classes.title}>
                      CTA0001586
                    </Typography>
                    <StarBorderOutlinedIcon className={classes.startIcn} />
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item lg={3} md={4} sm={4}>
            <Card className={classes.cardbody}>
              <CardActionArea>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img src={fileIcon} className={classes.fileiconstyle} />
                    <Typography className={classes.title}>
                      CTA0001586
                    </Typography>
                    <StarBorderOutlinedIcon className={classes.startIcn} />
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item lg={3} md={4} sm={4}>
            <Card className={classes.cardbody}>
              <CardActionArea>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img src={fileIcon} className={classes.fileiconstyle} />
                    <Typography className={classes.title}>
                      CTA0001586
                    </Typography>
                    <StarBorderOutlinedIcon className={classes.startIcn} />
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item lg={3} md={4} sm={4}>
            <Card className={classes.cardbody}>
              <CardActionArea>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img src={fileIcon} className={classes.fileiconstyle} />
                    <Typography className={classes.title}>
                      CTA0001586
                    </Typography>
                    <StarBorderOutlinedIcon className={classes.startIcn} />
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item lg={3} md={4} sm={4}>
            <Card className={classes.cardbody}>
              <CardActionArea>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img src={fileIcon} className={classes.fileiconstyle} />
                    <Typography className={classes.title}>
                      CTA0001586
                    </Typography>
                    <StarBorderOutlinedIcon className={classes.startIcn} />
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default CardItems;
