import React from "react";
import AllProjects from "./AllProjects";
import StatusCard from "../StatusCard";
import Graph from "./Graph";
import fileIcon from "../../images/files.svg"
import reviewIcon from "../../images/reviewIcon.svg"
import pushIcon from "../../images/pushIcon.svg"
import penddingIcon from "../../images/penddingIcon.svg"
import completedIcon from "../../images/completedIcon.svg"
import CardItems from "./CardItems";
function Dashboard() {
  const statusData = [
    {
      title: "Waiting for Filing",
      desc: "Total Pending Files",
      icon: fileIcon,
      digit: "00"
    },
    {
      title: "Under Review",
      desc: "Total Pending Review",
      icon: reviewIcon,
      digit: "00"
    },
    {
      title: "Pushed to FinCen",
      desc: "Total Pushed",
      icon: pushIcon,
      digit: "00"
    },
    {
      title: "Pending Details",
      desc: "Total Pending Details",
      icon: penddingIcon,
      digit: "00"
    },
    {
      title: "Completed Files",
      desc: "Total Completed Files",
      icon: completedIcon,
      digit: "00"
    }
  ]
  return (
    <div>

      <div style={{ display: "flex" }}>
        {statusData.map((items, index) => {
          return (
            <>
              <StatusCard key={index} title={items.title} desc={items.desc} digit={items.digit} icon={items.icon} />
            </>
          );
        })}
      </div>
      
      <AllProjects />
      {/* <Graph /> */}
    </div>
  );
}

export default Dashboard;
