import { Button, Container } from "@mui/material";
import React, { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import * as _ from "radash";
import AllProjects from "../Dashboard/AllProjects";
import CreateProject from "./CreateProject";
import ForgotPassword from "./ForgotPassword";
import PlanCards from "../Pricing/PlanCards";
import ProjectStatusCard from "./ProjectStatusCard";
import Setting from "./Setting";
import StepperProcess from "./StepperProcess";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import Column from "./Column";
import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";

const useStyle = makeStyles((theme) => ({
  main: {
    backgroundColor: "#F8F8F8",
    borderRadius: "5px",
    height: "850px",
  },
  MainWrapper: {
    display: "flex",
    justifyContent: "left",

    paddingTop: 40,
    paddingBottom: 40,
    fontFamily: "Anek Telugu",

  },
  btnbody: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  createbtn: {
    backgroundColor: "#3330E4 !important",
    textTransform: "capitalize !important",
    fontSize: "12px !important",
    fontWeight: "400 !important",
    fontFamily: "Poppins !important",
    display: "flex",
    alignItems: "center",
  },
}))
export const DEFAULT_COLUMN = "filedetails";
const COLUMNS = ["FileDetails", "Review by CTA"];
const DEFAULT_DATA_STATE = [
  {
    id: _.uid(6),
    content: "CTA0001586",
    column: DEFAULT_COLUMN,
  },
  {
    id: _.uid(6),
    content: "CTA0001587",
    column: DEFAULT_COLUMN,
  },
  {
    id: _.uid(6),
    content: "CTA0001588",
    column: DEFAULT_COLUMN,
  },
];
const Project = () => {
  const classes = useStyle();
  const [data, setData] = useState(DEFAULT_DATA_STATE);
  const handleOnDragEnd = useCallback(
    ({ active, over }) => {
      const elementId = active.id;
      const deepCopy = [...data];

      const updatedState = deepCopy.map((elm) => {
        if (elm.id === elementId) {
          const column = over?.id ? String(over.id) : elm.column;
          return { ...elm, column };
        }
        return elm;
      });

      setData(updatedState);
    },
    [data, setData]
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )
  return (
    <>
      <div className={classes.main}>
        <Container>
          <div className={classes.btnbody}>
            <NavLink to={`/Create`} style={{ textDecoration: 'none' }}>
              <Button className={classes.createbtn} variant="contained">
                <AddIcon />
                Create New Project
              </Button>
            </NavLink>
          </div>

          <DndContext onDragEnd={handleOnDragEnd} sensors={sensors}>
            <div className={classes.MainWrapper}>
              {COLUMNS.map((column, columnIndex) => (
                <Column
                  key={`column-${columnIndex}`}
                  heading={column}
                  elements={_.select(
                    data,
                    (elm) => elm,
                    (f) => f.column === _.camal(column)
                  )}
                />
              ))}

            </div>
          </DndContext>
        </Container>
      </div>
    </>
  );
};

export default Project;