import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Container,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import upload from "../../images/upload.svg";
import Modals from "../modals/Modals";
import { NavLink } from "react-router-dom";
import useForm from "../FormValidation/useForm";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// import Dropzone from "react-dropzone";
import Dropzone from "./Dropzone";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
const useStyle = makeStyles((theme) => ({
  main: {
    backgroundColor: "#F8F8F8",
    borderRadius: "5px",
    minHeight: "850px",
    paddingBottom: "50px",
  },
  usertitle: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    fontFamily: "Poppins !important",
    color: "#06283D",
    paddingTop: "20px",
  },
  usertitle2: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    fontFamily: "Poppins !important",
    color: "#06283D",
    paddingTop: "20px",
    textAlign: "center !important",
  },
  forminput: {
    background: "white",
    padding: "15px",
    borderRadius: "5px !important",
    width: "430px",
    border: "2px solid #E8E8E8",
    height: "50px",
    marginRight: "5px",
    color: "#BEBEBE !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
  },
  form: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  textfieldtitle: {
    paddingBottom: "15px",
    color: "#06283D !important",
    paddingTop: "30px",
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
  },
  uplaoddocbody: {
    width: "250px",
    // display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F1F9FE",
    border: "1px #3330E4",
    borderStyle: "dashed",
    borderRadius: "10px",
    borderWidth: "3px",
    padding: "20px",
    paddingBottom: 0,
    textAlign: "center",
  },

  filebtn: {
    backgroundColor: "#3330E4 !important",
    fontSize: "12px !important",
    textTransform: "capitalize !important",
  },
  uploadicn: {
    paddingRight: "7px",
  },
  btnbody: {
    marginTop: "20px",
  },
  savebtn: {
    marginRight: "0px !important",
    padding: "7px 40px !important",
    backgroundColor: "#3330E4 !important",
    fontSize: "12px !important",
    textTransform: "capitalize !important",
  },
  cancelbtn: {
    padding: "7px 40px !important",
    borderColor: "#CECECE !important",
    color: "black !important",
    fontSize: "12px !important",
    textTransform: "capitalize !important",
    marginLeft: "10px !important",
  },
  error: {
    color: "red !important",
    marginTop: "5px !important",
    fontSize: "12px !important",
    fontFamily: "Poppins !important",
  },
  field2: {
    borderRadius: "5px",
    padding: "11px",
    color: "#BEBEBE ",
    fontFamily: "poppins",
    fontSize: "16px ",
    fontWeight: "400",
    resize: "none",
    overflow: "hidden",
    width: "430px",
    border: "2px solid #E8E8E8",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    height: "50px",
  },
  fixDiv: {
    width: "100%",
    height: "350px",
  },
}));

const CreateProject = () => {
  const classes = useStyle();
  const [formation, setFormation] = useState("US");

  const handleChangeFormation = (e) => {
    setFormation(e.target.value);
  };
  const [owner, setOwner] = useState("USNational");

  const handleChangeOwner = (e) => {
    setOwner(e.target.value);
  };
  //Final submit function
  const formLogin = () => {
    console.log("Callback function when form is submitted!");
    console.log("Form Values ", values);
  };

  //Custom hook call
  const { handleChange, values, errors, handleSubmit } = useForm(formLogin);

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    console.log("STATE_CHANGED");
  });
  const [value, setValue] = React.useState(dayjs("2022-12-30"));

  const handleChange2 = (newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.main}>
          <Container>
            <Typography className={classes.usertitle}>
              Create New Project
            </Typography>
            <Typography className={classes.usertitle2}>
              Company Details
            </Typography>
            <div className={classes.form}>
              <div>
                <Typography className={classes.textfieldtitle}>
                  Company Full Legal Name
                </Typography>
                <TextField
                  variant="standard"
                  type="text"
                  minLength="5"
                  required
                  name="username"
                  placeholder="Enter Your Company's Legal Name"
                  onChange={handleChange}
                  InputProps={{
                    className: classes.forminput,
                    disableUnderline: true,
                  }}
                  // placeholder="Lorem Ipsum"
                  disableUnderline={true}
                />
                {errors.username && (
                  <Typography className={classes.error}>
                    {errors.username}
                  </Typography>
                )}
              </div>
              <div>
                <Typography className={classes.textfieldtitle}>
                  Company Current Legal Address
                </Typography>
                <textarea
                  placeholder="Enter Company's Legal Address"
                  rows="1"
                  cols="0"
                  className={classes.field2}
                />
                <div>
                  {errors.lorem && (
                    <Typography className={classes.error}>
                      {errors.lorem}
                    </Typography>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.form}>
              <div>
                <Typography className={classes.textfieldtitle}>
                  Place of company formation
                </Typography>
                <RadioGroup
                  row
                  onChange={handleChangeFormation}
                  value="formation"
                  name="formation"
                >
                  <FormControlLabel
                    value="US Jurisdiction"
                    control={
                      <Radio
                        value="US"
                        name="formation"
                        checked={formation === "US" ? true : false}
                      />
                    }
                    label="US Jurisdiction"
                  />
                  <FormControlLabel
                    value="Foreign Formation"
                    control={
                      <Radio
                        value="foreign"
                        name="formation"
                        checked={formation === "foreign" ? true : false}
                      />
                    }
                    label="Foreign Formation"
                  />
                  {console.log("formation", formation)}
                </RadioGroup>
              </div>
            </div>
            {formation === "foreign" ? (
              <div>
                <div className={classes.form}>
                  <div>
                    <Typography className={classes.textfieldtitle}>
                      Country of Formation
                    </Typography>
                    <TextField
                      variant="standard"
                      InputProps={{
                        className: classes.forminput,
                        disableUnderline: true,
                      }}
                      placeholder="Lorem Ipsum"
                      disableUnderline={true}
                    />
                  </div>
                  <div>
                    <Typography className={classes.textfieldtitle}>
                      State of Formation
                    </Typography>
                    <TextField
                      variant="standard"
                      InputProps={{
                        className: classes.forminput,
                        disableUnderline: true,
                      }}
                      placeholder="Lorem Ipsum"
                      disableUnderline={true}
                    />
                  </div>
                </div>
                <Typography className={classes.textfieldtitle}>
                  Company Registration Document
                </Typography>
                <div>
                  <Dropzone />
                </div>
              </div>
            ) : (
              ""
            )}
            {formation === "US" ? (
              <div className={classes.form}>
                <div>
                  <Typography className={classes.textfieldtitle}>
                    State Of Formation
                  </Typography>
                  <TextField
                    variant="standard"
                    InputProps={{
                      className: classes.forminput,
                      disableUnderline: true,
                    }}
                    placeholder="Lorem Ipsum"
                    disableUnderline={true}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={classes.form}>
              <div>
                <Typography className={classes.textfieldtitle}>
                  TIN and EIN number
                </Typography>
                <TextField
                  variant="standard"
                  InputProps={{
                    className: classes.forminput,
                    disableUnderline: true,
                  }}
                  placeholder="Lorem Ipsum"
                  disableUnderline={true}
                />
              </div>
              <div>
                <Typography className={classes.textfieldtitle}>
                  Foreign Based Company
                </Typography>
                <TextField
                  variant="standard"
                  InputProps={{
                    className: classes.forminput,
                    disableUnderline: true,
                  }}
                  placeholder="Lorem Ipsum"
                  disableUnderline={true}
                />
              </div>
            </div>
            <div className={classes.form}>
              <div>
                <Typography className={classes.textfieldtitle}>
                  Company Registration number or Code
                </Typography>
                <TextField
                  variant="standard"
                  InputProps={{
                    className: classes.forminput,
                    disableUnderline: true,
                  }}
                  placeholder="Lorem Ipsum"
                  disableUnderline={true}
                />
              </div>
            </div>
            <div style={{ marginTop: "60px" }}>
              <Typography className={classes.usertitle2}>
                Company's Owner Details
              </Typography>
            </div>
            <div className={classes.form}>
              <div>
                <Typography className={classes.textfieldtitle}>
                  Business Owner Legal Name
                </Typography>
                <TextField
                  variant="standard"
                  InputProps={{
                    className: classes.forminput,
                    disableUnderline: true,
                  }}
                  placeholder="Lorem Ipsum"
                  disableUnderline={true}
                />
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Typography className={classes.textfieldtitle}>
                    Date Of Birth
                  </Typography>
                  <DatePicker
                    inputFormat="MM/DD/YYYY"
                    value={value}
                    onChange={handleChange2}
                    renderInput={(params) => <TextField {...params} />}
                    InputProps={{
                      className: classes.forminput,
                      disableUnderline: true,
                    }}
                    disableUnderline={true}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className={classes.form}>
              <div>
                <Typography className={classes.textfieldtitle}>
                  Address
                </Typography>
                <textarea rows="1" cols="0" className={classes.field2} />
                <div>
                  {errors.lorem && (
                    <Typography className={classes.error}>
                      {errors.lorem}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.form}>
              <div>
                <Typography className={classes.textfieldtitle}>
                  Submit Unique ID choose one
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  onChange={handleChangeOwner}
                  value="owner"
                  name="owner"
                >
                  <FormControlLabel
                    value="USNational"
                    control={
                      <Radio
                        value="USNational"
                        name="owner"
                        checked={owner === "USNational" ? true : false}
                      />
                    }
                    label="US National"
                  />
                  <FormControlLabel
                    value="Foreign National"
                    control={
                      <Radio
                        value="foreignNational"
                        name="owner"
                        checked={owner === "foreignNational" ? true : false}
                      />
                    }
                    label="Foreign National"
                  />
                  {console.log("owner", formation)}
                </RadioGroup>
              </div>
            </div>
            {owner === "USNational" ? (
              <div>
                <Typography className={classes.textfieldtitle}>
                  Driving License & Passport
                </Typography>
                <div>
                  <Dropzone />
                </div>
              </div>
            ) : (
              ""
            )}
            {owner === "foreignNational" ? (
              <div>
                <Typography className={classes.textfieldtitle}>
                  Passport
                </Typography>
                <div>
                  <Dropzone />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={classes.btnbody}>
              <NavLink
                to={`/Create/Process`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  type="submit"
                  value="Submit"
                  variant="contained"
                  className={classes.savebtn}
                >
                  create
                </Button>
              </NavLink>
              <Button variant="outlined" className={classes.cancelbtn}>
                cancel
              </Button>
            </div>
          </Container>
          {console.log("fileLength", fileList.length)}
          {/* {console.log("filetype", fileList.type)} */}
        </div>
      </form>
    </>
  );
};

export default CreateProject;
